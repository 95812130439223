import { emptySplitApi as api } from './emptyApi';
export const addTagTypes = [
  'AUTH',
  'ADMIN UI',
  'LOGIN PAGE',
  'USER UI',
  'SOCIAL NETWORKS',
  'USERS',
  'CAMPAIGN DETAIL PAGE',
  'ORDERS',
  'CAMPAIGN REQUEST PAGE',
  'MY ORDERS PAGE',
  'EDIT USER EDIT ADDRESS PAGE',
  'EDIT USER EDIT RATINGS PAGE',
  'EDIT USER GENERAL PAGE',
  'EDIT USER NOTIFICATIONS PAGE',
  'EDIT USER RATINGS OVERVIEW PAGE',
  'EDIT USER SOCIAL NETWORK LINKS PAGE',
  'EDIT USER SOCIAL NETWORK STATISITCS',
  'USER ACCOUNT PAGE',
  'USER ACCOUT CHANGE PASSWORD PAGE',
  'EDIT USER ADD ADDRESS PAGE',
  'CLUBS',
  'MANAGEMENT CLUBS PAGE',
  'CREATE CLUB PAGE',
  'CHAT',
  'CHAT PAGE',
  'CREATE CAMPAIGN TARGETING PAGE',
  'CREATE USER PAGE',
  'NOTIFICATIONS',
  'SIGNUP PAGE',
  'SIGNUP STEPPER PAGE',
  'CAMPAIGNS',
  'SHOP PAGE',
  'STATISTICS',
  'USER SOCIAL NETWORK MEDIAS PAGE',
  'MEDIAS',
  'MY ORDERS ADD IG MEDIA PAGE',
  'MY ORDERS ADD IG STORY PAGE',
  'MY ORDERS ADD IG MEDIA BY UPLOAD PAGE',
  'MY ORDERS ADD IG STORY BY UPLOAD PAGE',
  'MY ORDERS ADD IG REEL BY UPLOAD PAGE',
  'MY ORDERS ADD IG REEL PAGE',
  'MY ORDERS ADD YOUTUBE VIDEO PAGE',
  'MY ORDERS ADD YOUTUBE VIDEO BY UPLOAD PAGE',
  'MY ORDERS ADD YOUTUBE SHORTS PAGE',
  'MY ORDERS ADD YOUTUBE SHORTS UPLOAD PAGE',
  'MY ORDERS ADD TIKTOK CONTENT BY UPLOAD PAGE',
  'MY ORDERS ADD BLOG LINK PAGE',
  'UPLOAD',
  'CREATE CAMPAIGN CONTENT PAGE',
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      login: build.mutation<LoginApiResponse, LoginApiArg>({
        query: (queryArg) => ({ url: `/user/login`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'ADMIN UI', 'LOGIN PAGE', 'USER UI'],
      }),
      facebookLogin: build.mutation<FacebookLoginApiResponse, FacebookLoginApiArg>({
        query: (queryArg) => ({ url: `/user/facebook-login`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'LOGIN PAGE', 'USER UI'],
      }),
      googleLogin: build.mutation<GoogleLoginApiResponse, GoogleLoginApiArg>({
        query: (queryArg) => ({ url: `/user/google-login`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'LOGIN PAGE', 'USER UI'],
      }),
      logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
        query: () => ({ url: `/user/logout`, method: 'POST' }),
        invalidatesTags: ['AUTH', 'ADMIN UI', 'USER UI'],
      }),
      postUserBlog: build.mutation<PostUserBlogApiResponse, PostUserBlogApiArg>({
        query: (queryArg) => ({ url: `/user/blog`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['USER UI', 'SOCIAL NETWORKS'],
      }),
      postUserForgotPassword: build.mutation<
        PostUserForgotPasswordApiResponse,
        PostUserForgotPasswordApiArg
      >({
        query: (queryArg) => ({
          url: `/user/forgot-password`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['USERS', 'LOGIN PAGE', 'ADMIN UI', 'USER UI'],
      }),
      getUserUserCampaignsByCampaignId: build.query<
        GetUserUserCampaignsByCampaignIdApiResponse,
        GetUserUserCampaignsByCampaignIdApiArg
      >({
        query: (queryArg) => ({ url: `/user/user-campaigns/${queryArg.campaignId}` }),
        providesTags: ['USERS', 'ADMIN UI', 'USER UI', 'CAMPAIGN DETAIL PAGE'],
      }),
      postUserOrders: build.mutation<PostUserOrdersApiResponse, PostUserOrdersApiArg>({
        query: (queryArg) => ({ url: `/user/orders`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['ORDERS', 'CAMPAIGN REQUEST PAGE', 'USER UI'],
      }),
      getUserOrders: build.query<GetUserOrdersApiResponse, GetUserOrdersApiArg>({
        query: () => ({ url: `/user/orders` }),
        providesTags: ['ORDERS'],
      }),
      updateOrder: build.mutation<UpdateOrderApiResponse, UpdateOrderApiArg>({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['ORDERS', 'ADMIN UI', 'USER UI', 'MY ORDERS PAGE'],
      }),
      deleteUserOrdersByOrderId: build.mutation<
        DeleteUserOrdersByOrderIdApiResponse,
        DeleteUserOrdersByOrderIdApiArg
      >({
        query: (queryArg) => ({ url: `/user/orders/${queryArg.orderId}`, method: 'DELETE' }),
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: () => ({ url: `/user` }),
        providesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER EDIT ADDRESS PAGE',
          'EDIT USER EDIT RATINGS PAGE',
          'EDIT USER GENERAL PAGE',
          'EDIT USER NOTIFICATIONS PAGE',
          'EDIT USER RATINGS OVERVIEW PAGE',
          'EDIT USER SOCIAL NETWORK LINKS PAGE',
          'EDIT USER SOCIAL NETWORK STATISITCS',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      patchUser: build.mutation<PatchUserApiResponse, PatchUserApiArg>({
        query: (queryArg) => ({ url: `/user`, method: 'PATCH', body: queryArg.body }),
      }),
      patchUserGeneral: build.mutation<PatchUserGeneralApiResponse, PatchUserGeneralApiArg>({
        query: (queryArg) => ({ url: `/user/general`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER GENERAL PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      patchUserPassword: build.mutation<PatchUserPasswordApiResponse, PatchUserPasswordApiArg>({
        query: (queryArg) => ({ url: `/user/password`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS', 'USER ACCOUT CHANGE PASSWORD PAGE', 'USER UI'],
      }),
      patchUserLang: build.mutation<PatchUserLangApiResponse, PatchUserLangApiArg>({
        query: (queryArg) => ({ url: `/user/lang`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS', 'ADMIN UI', 'USER UI'],
      }),
      patchUserEmail: build.mutation<PatchUserEmailApiResponse, PatchUserEmailApiArg>({
        query: (queryArg) => ({ url: `/user/email`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS', 'USER ACCOUNT PAGE', 'USER UI', 'ADMIN UI'],
      }),
      patchUserPhoneNumber: build.mutation<
        PatchUserPhoneNumberApiResponse,
        PatchUserPhoneNumberApiArg
      >({
        query: (queryArg) => ({ url: `/user/phone-number`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS', 'USER ACCOUNT PAGE', 'USER UI', 'ADMIN UI'],
      }),
      createNewAddressForOneUser: build.mutation<
        CreateNewAddressForOneUserApiResponse,
        CreateNewAddressForOneUserApiArg
      >({
        query: (queryArg) => ({
          url: `/user/addresses`,
          method: 'POST',
          body: queryArg.addressItemPost,
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER ADD ADDRESS PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      updateOneUserAddress: build.mutation<
        UpdateOneUserAddressApiResponse,
        UpdateOneUserAddressApiArg
      >({
        query: (queryArg) => ({
          url: `/user/addresses/${queryArg.addressId}`,
          method: 'PUT',
          body: queryArg.addressItem,
        }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER EDIT ADDRESS PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      deleteOneUserAddress: build.mutation<
        DeleteOneUserAddressApiResponse,
        DeleteOneUserAddressApiArg
      >({
        query: (queryArg) => ({ url: `/user/addresses/${queryArg.addressId}`, method: 'DELETE' }),
        invalidatesTags: [
          'USERS',
          'ADMIN UI',
          'EDIT USER GENERAL PAGE',
          'USER UI',
          'USER ACCOUNT PAGE',
        ],
      }),
      getLanguages: build.query<GetLanguagesApiResponse, GetLanguagesApiArg>({
        query: () => ({ url: `/user/languages` }),
        providesTags: ['CLUBS', 'MANAGEMENT CLUBS PAGE', 'ADMIN UI'],
      }),
      postLanguages: build.mutation<PostLanguagesApiResponse, PostLanguagesApiArg>({
        query: (queryArg) => ({
          url: `/user/languages`,
          method: 'POST',
          body: queryArg.clubItemForPost,
        }),
        invalidatesTags: ['CLUBS', 'CREATE CLUB PAGE', 'ADMIN UI'],
      }),
      getUserChatConversations: build.query<
        GetUserChatConversationsApiResponse,
        GetUserChatConversationsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/chat/conversations`,
          params: { search: queryArg.search, limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['CHAT', 'CHAT PAGE', 'USER UI'],
      }),
      postUserChatNewConversation: build.mutation<
        PostUserChatNewConversationApiResponse,
        PostUserChatNewConversationApiArg
      >({
        query: (queryArg) => ({
          url: `/user/chat/new-conversation`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['CHAT', 'CHAT PAGE', 'USER UI'],
      }),
      getUserChatConversationsByConversationIdMessages: build.query<
        GetUserChatConversationsByConversationIdMessagesApiResponse,
        GetUserChatConversationsByConversationIdMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/user/chat/conversations/${queryArg.conversationId}/messages`,
          params: { limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE', 'USER UI'],
      }),
      postUserChatConversationsByConversationIdMessages: build.mutation<
        PostUserChatConversationsByConversationIdMessagesApiResponse,
        PostUserChatConversationsByConversationIdMessagesApiArg
      >({
        query: (queryArg) => ({
          url: `/user/chat/conversations/${queryArg.conversationId}/messages`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['CHAT', 'ADMIN UI', 'CHAT PAGE', 'USER UI'],
      }),
      postUserChatConversationsByConversationIdMarkAsRead: build.mutation<
        PostUserChatConversationsByConversationIdMarkAsReadApiResponse,
        PostUserChatConversationsByConversationIdMarkAsReadApiArg
      >({
        query: (queryArg) => ({
          url: `/user/chat/conversations/${queryArg.conversationId}/mark-as-read`,
          method: 'POST',
        }),
        invalidatesTags: ['CHAT', 'CHAT PAGE', 'USER UI'],
      }),
      getUserInterests: build.query<GetUserInterestsApiResponse, GetUserInterestsApiArg>({
        query: () => ({ url: `/user/interests` }),
        providesTags: [
          'USERS',
          'ADMIN UI',
          'CREATE CAMPAIGN TARGETING PAGE',
          'CREATE USER PAGE',
          'EDIT USER GENERAL PAGE',
          'USER UI',
        ],
      }),
      postUserInterests: build.mutation<PostUserInterestsApiResponse, PostUserInterestsApiArg>({
        query: () => ({ url: `/user/interests`, method: 'POST' }),
      }),
      getUserNotifications: build.query<
        GetUserNotificationsApiResponse,
        GetUserNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/notifications`,
          params: { limit: queryArg.limit, cursor: queryArg.cursor },
        }),
        providesTags: ['NOTIFICATIONS', 'USER UI', 'USERS'],
      }),
      patchUserNotifications: build.mutation<
        PatchUserNotificationsApiResponse,
        PatchUserNotificationsApiArg
      >({
        query: (queryArg) => ({ url: `/user/notifications`, method: 'PATCH', body: queryArg.body }),
      }),
      putUserNotificationsRead: build.mutation<
        PutUserNotificationsReadApiResponse,
        PutUserNotificationsReadApiArg
      >({
        query: (queryArg) => ({
          url: `/user/notifications/read`,
          method: 'PUT',
          body: queryArg.body,
        }),
        invalidatesTags: ['NOTIFICATIONS', 'USERS', 'ADMIN UI', 'USER UI'],
      }),
      postUserRegistration: build.mutation<
        PostUserRegistrationApiResponse,
        PostUserRegistrationApiArg
      >({
        query: (queryArg) => ({ url: `/user/registration`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'USERS', 'SIGNUP PAGE', 'USER UI'],
      }),
      postUserRegistrationResendEmailCode: build.mutation<
        PostUserRegistrationResendEmailCodeApiResponse,
        PostUserRegistrationResendEmailCodeApiArg
      >({
        query: () => ({ url: `/user/registration/resend-email-code`, method: 'POST' }),
        invalidatesTags: ['AUTH', 'SIGNUP STEPPER PAGE', 'USER UI', 'USERS'],
      }),
      postUserRegistrationCheckEmail: build.mutation<
        PostUserRegistrationCheckEmailApiResponse,
        PostUserRegistrationCheckEmailApiArg
      >({
        query: (queryArg) => ({
          url: `/user/registration/check-email`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH', 'SIGNUP STEPPER PAGE', 'USERS', 'USER UI'],
      }),
      postUserRegistrationGeneral: build.mutation<
        PostUserRegistrationGeneralApiResponse,
        PostUserRegistrationGeneralApiArg
      >({
        query: (queryArg) => ({
          url: `/user/registration/general`,
          method: 'POST',
          body: queryArg.userItemForPostRegistrationGeneral,
        }),
        invalidatesTags: ['AUTH', 'SIGNUP STEPPER PAGE', 'USERS', 'USER UI'],
      }),
      postUserRegistrationResendPhoneCode: build.mutation<
        PostUserRegistrationResendPhoneCodeApiResponse,
        PostUserRegistrationResendPhoneCodeApiArg
      >({
        query: () => ({ url: `/user/registration/resend-phone-code`, method: 'POST' }),
        invalidatesTags: ['AUTH', 'SIGNUP STEPPER PAGE', 'USER UI', 'USERS'],
      }),
      postUserRegistrationCheckPhoneNumber: build.mutation<
        PostUserRegistrationCheckPhoneNumberApiResponse,
        PostUserRegistrationCheckPhoneNumberApiArg
      >({
        query: (queryArg) => ({
          url: `/user/registration/check-phone-number`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['AUTH', 'SIGNUP STEPPER PAGE', 'USERS', 'USER UI'],
      }),
      getUserUserCampaigns: build.query<
        GetUserUserCampaignsApiResponse,
        GetUserUserCampaignsApiArg
      >({
        query: () => ({ url: `/user/user-campaigns` }),
        providesTags: ['CAMPAIGNS', 'USER UI', 'SHOP PAGE'],
      }),
      getUserChatPossibleConversations: build.query<
        GetUserChatPossibleConversationsApiResponse,
        GetUserChatPossibleConversationsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/chat/possible-conversations`,
          params: { search: queryArg.search },
        }),
        providesTags: ['CHAT', 'ORDERS', 'USER UI', 'CHAT PAGE'],
      }),
      getUserMediasStatistics: build.query<
        GetUserMediasStatisticsApiResponse,
        GetUserMediasStatisticsApiArg
      >({
        query: () => ({ url: `/user/medias-statistics` }),
        providesTags: [
          'SOCIAL NETWORKS',
          'STATISTICS',
          'USER SOCIAL NETWORK MEDIAS PAGE',
          'USER UI',
          'USERS',
        ],
      }),
      getUserIgMedias: build.query<GetUserIgMediasApiResponse, GetUserIgMediasApiArg>({
        query: (queryArg) => ({
          url: `/user/ig-medias`,
          params: {
            cursor: queryArg.cursor,
            'get-fresh-data': queryArg['get-fresh-data'],
            limit: queryArg.limit,
          },
        }),
        providesTags: ['MEDIAS', 'MY ORDERS ADD IG MEDIA PAGE', 'ORDERS', 'USER UI'],
      }),
      getUsersByUserIdIgStories: build.query<
        GetUsersByUserIdIgStoriesApiResponse,
        GetUsersByUserIdIgStoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/${queryArg.userId}/ig-stories`,
          params: {
            cursor: queryArg.cursor,
            'get-fresh-data': queryArg['get-fresh-data'],
            limit: queryArg.limit,
          },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD IG STORY PAGE'],
      }),
      getUserIgStories: build.query<GetUserIgStoriesApiResponse, GetUserIgStoriesApiArg>({
        query: (queryArg) => ({
          url: `/user/ig-stories`,
          params: {
            'get-fresh-data': queryArg['get-fresh-data'],
            cursor: queryArg.cursor,
            limit: queryArg.limit,
          },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD IG STORY PAGE'],
      }),
      getUserIgReels: build.query<GetUserIgReelsApiResponse, GetUserIgReelsApiArg>({
        query: (queryArg) => ({
          url: `/user/ig-reels`,
          params: {
            cursor: queryArg.cursor,
            'get-fresh-data': queryArg['get-fresh-data'],
            limit: queryArg.limit,
          },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD IG STORY PAGE'],
      }),
      deleteUserOrdersByOrderIdMediasAndMediaId: build.mutation<
        DeleteUserOrdersByOrderIdMediasAndMediaIdApiResponse,
        DeleteUserOrdersByOrderIdMediasAndMediaIdApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/medias/${queryArg.mediaId}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['MEDIAS', 'USER UI', 'ORDERS', 'MY ORDERS PAGE'],
      }),
      getUserOrdersByOrderIdIgMedias: build.query<
        GetUserOrdersByOrderIdIgMediasApiResponse,
        GetUserOrdersByOrderIdIgMediasApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-medias`,
          params: { pagination: queryArg.pagination },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdIgMedias: build.mutation<
        PostUserOrdersByOrderIdIgMediasApiResponse,
        PostUserOrdersByOrderIdIgMediasApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-medias`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: [
          'MEDIAS',
          'ORDERS',
          'MY ORDERS ADD IG MEDIA BY UPLOAD PAGE',
          'STATISTICS',
          'USER UI',
        ],
      }),
      patchUserOrdersByOrderIdIgMedias: build.mutation<
        PatchUserOrdersByOrderIdIgMediasApiResponse,
        PatchUserOrdersByOrderIdIgMediasApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-medias`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      getUserOrdersByOrderIdIgStories: build.query<
        GetUserOrdersByOrderIdIgStoriesApiResponse,
        GetUserOrdersByOrderIdIgStoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-stories`,
          params: { pagination: queryArg.pagination },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdIgStories: build.mutation<
        PostUserOrdersByOrderIdIgStoriesApiResponse,
        PostUserOrdersByOrderIdIgStoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-stories`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD IG STORY BY UPLOAD PAGE'],
      }),
      patchUserOrdersByOrderIdIgStories: build.mutation<
        PatchUserOrdersByOrderIdIgStoriesApiResponse,
        PatchUserOrdersByOrderIdIgStoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-stories`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'MY ORDERS ADD IG STORY PAGE', 'USER UI', 'ORDERS'],
      }),
      getUserOrdersByOrderIdIgReels: build.query<
        GetUserOrdersByOrderIdIgReelsApiResponse,
        GetUserOrdersByOrderIdIgReelsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-reels`,
          params: { pagination: queryArg.pagination },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdIgReels: build.mutation<
        PostUserOrdersByOrderIdIgReelsApiResponse,
        PostUserOrdersByOrderIdIgReelsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-reels`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD IG REEL BY UPLOAD PAGE'],
      }),
      patchUserOrdersByOrderIdIgReels: build.mutation<
        PatchUserOrdersByOrderIdIgReelsApiResponse,
        PatchUserOrdersByOrderIdIgReelsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/ig-reels`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD IG REEL PAGE'],
      }),
      getUserYoutube: build.query<GetUserYoutubeApiResponse, GetUserYoutubeApiArg>({
        query: (queryArg) => ({
          url: `/user/youtube`,
          params: { pagination: queryArg.pagination, 'get-fresh-data': queryArg['get-fresh-data'] },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD YOUTUBE VIDEO PAGE'],
      }),
      getUserOrdersByOrderIdYoutube: build.query<
        GetUserOrdersByOrderIdYoutubeApiResponse,
        GetUserOrdersByOrderIdYoutubeApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/youtube`,
          params: { pagination: queryArg.pagination },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdYoutube: build.mutation<
        PostUserOrdersByOrderIdYoutubeApiResponse,
        PostUserOrdersByOrderIdYoutubeApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/youtube`,
          method: 'POST',
          body: queryArg.uploadYoutubeContentItem,
        }),
        invalidatesTags: [
          'MEDIAS',
          'ORDERS',
          'USER UI',
          'MY ORDERS ADD YOUTUBE VIDEO BY UPLOAD PAGE',
        ],
      }),
      patchUserOrdersByOrderIdYoutube: build.mutation<
        PatchUserOrdersByOrderIdYoutubeApiResponse,
        PatchUserOrdersByOrderIdYoutubeApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/youtube`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'USER UI'],
      }),
      getUserYoutubeShorts: build.query<
        GetUserYoutubeShortsApiResponse,
        GetUserYoutubeShortsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/youtube-shorts`,
          params: { pagination: queryArg.pagination, 'get-fresh-data': queryArg['get-fresh-data'] },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD YOUTUBE SHORTS PAGE'],
      }),
      getUserOrdersByOrderIdYoutubeShorts: build.query<
        GetUserOrdersByOrderIdYoutubeShortsApiResponse,
        GetUserOrdersByOrderIdYoutubeShortsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/youtube-shorts`,
          params: { pagination: queryArg.pagination },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdYoutubeShorts: build.mutation<
        PostUserOrdersByOrderIdYoutubeShortsApiResponse,
        PostUserOrdersByOrderIdYoutubeShortsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/youtube-shorts`,
          method: 'POST',
          body: queryArg.uploadYoutubeContentItem,
        }),
        invalidatesTags: [
          'MEDIAS',
          'ORDERS',
          'USER UI',
          'MY ORDERS ADD YOUTUBE SHORTS UPLOAD PAGE',
        ],
      }),
      patchUserOrdersByOrderIdYoutubeShorts: build.mutation<
        PatchUserOrdersByOrderIdYoutubeShortsApiResponse,
        PatchUserOrdersByOrderIdYoutubeShortsApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/youtube-shorts`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'USER UI'],
      }),
      getUserTiktok: build.query<GetUserTiktokApiResponse, GetUserTiktokApiArg>({
        query: (queryArg) => ({
          url: `/user/tiktok`,
          params: { pagination: queryArg.pagination, 'get-fresh-data': queryArg['get-fresh-data'] },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI'],
      }),
      getUserOrdersByOrderIdTiktok: build.query<
        GetUserOrdersByOrderIdTiktokApiResponse,
        GetUserOrdersByOrderIdTiktokApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/tiktok`,
          params: { pagination: queryArg.pagination },
        }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdTiktok: build.mutation<
        PostUserOrdersByOrderIdTiktokApiResponse,
        PostUserOrdersByOrderIdTiktokApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/tiktok`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: [
          'MEDIAS',
          'ORDERS',
          'USER UI',
          'MY ORDERS ADD TIKTOK CONTENT BY UPLOAD PAGE',
        ],
      }),
      patchUserOrdersByOrderIdTiktok: build.mutation<
        PatchUserOrdersByOrderIdTiktokApiResponse,
        PatchUserOrdersByOrderIdTiktokApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/tiktok`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'USER UI', 'ORDERS'],
      }),
      patchUserOrdersByOrderIdTiktokAndContentId: build.mutation<
        PatchUserOrdersByOrderIdTiktokAndContentIdApiResponse,
        PatchUserOrdersByOrderIdTiktokAndContentIdApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/tiktok/${queryArg.contentId}`,
          method: 'PATCH',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'USER UI', 'ORDERS'],
      }),
      getUserOrdersByOrderIdBlog: build.query<
        GetUserOrdersByOrderIdBlogApiResponse,
        GetUserOrdersByOrderIdBlogApiArg
      >({
        query: (queryArg) => ({ url: `/user/orders/${queryArg.orderId}/blog` }),
        providesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS PAGE'],
      }),
      postUserOrdersByOrderIdBlog: build.mutation<
        PostUserOrdersByOrderIdBlogApiResponse,
        PostUserOrdersByOrderIdBlogApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/blog`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['MEDIAS', 'ORDERS', 'USER UI', 'MY ORDERS ADD BLOG LINK PAGE'],
      }),
      postUserUpload: build.mutation<PostUserUploadApiResponse, PostUserUploadApiArg>({
        query: (queryArg) => ({ url: `/user/upload`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['UPLOAD', 'CREATE CAMPAIGN CONTENT PAGE', 'ADMIN UI'],
      }),
      postUserRefreshToken: build.mutation<
        PostUserRefreshTokenApiResponse,
        PostUserRefreshTokenApiArg
      >({
        query: (queryArg) => ({ url: `/user/refresh-token`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'ADMIN UI', 'USER UI'],
      }),
      postUserResetPassword: build.mutation<
        PostUserResetPasswordApiResponse,
        PostUserResetPasswordApiArg
      >({
        query: (queryArg) => ({ url: `/user/reset-password`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['AUTH', 'USER UI', 'ADMIN UI'],
      }),
      getUserChatConversationsByConversationIdFiles: build.query<
        GetUserChatConversationsByConversationIdFilesApiResponse,
        GetUserChatConversationsByConversationIdFilesApiArg
      >({
        query: (queryArg) => ({ url: `/user/chat/conversations/${queryArg.conversationId}/files` }),
        providesTags: ['CHAT', 'USER UI', 'CHAT PAGE'],
      }),
      postUserChatUpload: build.mutation<PostUserChatUploadApiResponse, PostUserChatUploadApiArg>({
        query: (queryArg) => ({ url: `/user/chat/upload`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['CHAT', 'ADMIN UI', 'USER UI', 'CHAT PAGE'],
      }),
      getUserBrands: build.query<GetUserBrandsApiResponse, GetUserBrandsApiArg>({
        query: () => ({ url: `/user/brands` }),
      }),
      getUserIndustries: build.query<GetUserIndustriesApiResponse, GetUserIndustriesApiArg>({
        query: () => ({ url: `/user/industries` }),
      }),
      postUserChatAuth: build.mutation<PostUserChatAuthApiResponse, PostUserChatAuthApiArg>({
        query: (queryArg) => ({ url: `/user/chat/auth`, method: 'POST', body: queryArg.body }),
      }),
      postUserNotificationsAuth: build.mutation<
        PostUserNotificationsAuthApiResponse,
        PostUserNotificationsAuthApiArg
      >({
        query: (queryArg) => ({
          url: `/user/notifications/auth`,
          method: 'POST',
          body: queryArg.body,
        }),
      }),
      getUserNotificationsPusherBeamsAuth: build.query<
        GetUserNotificationsPusherBeamsAuthApiResponse,
        GetUserNotificationsPusherBeamsAuthApiArg
      >({
        query: () => ({ url: `/user/notifications/pusher/beams-auth` }),
      }),
      getUserIgSetCard: build.query<GetUserIgSetCardApiResponse, GetUserIgSetCardApiArg>({
        query: () => ({ url: `/user/ig-set-card` }),
        providesTags: ['STATISTICS'],
      }),
      postUserConsent: build.mutation<PostUserConsentApiResponse, PostUserConsentApiArg>({
        query: (queryArg) => ({ url: `/user/consent`, method: 'POST', body: queryArg.body }),
        invalidatesTags: ['USERS'],
      }),
      patchUserOnboarding: build.mutation<
        PatchUserOnboardingApiResponse,
        PatchUserOnboardingApiArg
      >({
        query: (queryArg) => ({ url: `/user/onboarding`, method: 'PATCH', body: queryArg.body }),
        invalidatesTags: ['USERS'],
      }),
      patchUserOrdersByOrderIdStatus: build.mutation<
        PatchUserOrdersByOrderIdStatusApiResponse,
        PatchUserOrdersByOrderIdStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/user/orders/${queryArg.orderId}/status`,
          method: 'PATCH',
          body: queryArg.body,
        }),
      }),
      getUserLeaderboard: build.query<GetUserLeaderboardApiResponse, GetUserLeaderboardApiArg>({
        query: () => ({ url: `/user/leaderboard` }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as coreApi };
export type LoginApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    role?: 'admin' | 'brand' | 'influencer';
  };
  token?: string;
  refreshToken?: string;
};
export type LoginApiArg = {
  /** A JSON Object containing user credential */
  body: {
    /** Email */
    email?: string;
    /** password */
    password?: string;
  };
};
export type FacebookLoginApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    role?: 'admin' | 'brand' | 'influencer';
  };
  token?: string;
  refreshToken?: string;
};
export type FacebookLoginApiArg = {
  /** A JSON Object containing user credential */
  body: {
    userID?: string;
    accessToken?: string;
    data_access_expiration_time?: number;
    expiresIn?: number;
    graphDomain?: string;
    signedRequest?: string;
    lang?: Lang;
  };
};
export type GoogleLoginApiResponse = /** status 200 OK */ {
  user?: {
    id?: string;
    role?: 'admin' | 'brand' | 'influencer';
  };
  token?: string;
  refreshToken?: string;
};
export type GoogleLoginApiArg = {
  /** A JSON Object containing user credential */
  body: {
    clientId?: string;
    credential?: string;
    accessToken?: string;
    expiresIn?: number;
    lang?: Lang;
  };
};
export type LogoutApiResponse = /** status 204 No Content */ {};
export type LogoutApiArg = void;
export type PostUserBlogApiResponse = /** status 204 No Content */ {};
export type PostUserBlogApiArg = {
  body: {
    /** Url of users blog */
    url: string;
    /** estimated montly visitors */
    visitors: number;
  };
};
export type PostUserForgotPasswordApiResponse = unknown;
export type PostUserForgotPasswordApiArg = {
  /** User's email */
  body: {
    email?: string;
  };
};
export type GetUserUserCampaignsByCampaignIdApiResponse =
  /** status 200 OK */ CampaignItemForSingleCampaignInUserUi;
export type GetUserUserCampaignsByCampaignIdApiArg = {
  /** ID of campaign to update */
  campaignId: string;
};
export type PostUserOrdersApiResponse = /** status 201 Created */ {
  id?: Uuid;
};
export type PostUserOrdersApiArg = {
  body: {
    userId?: Uuid;
    campaignId?: Uuid;
    address?: AddressItemForOrders;
    motivation?: string;
    options?: {
      key?: string;
      value?: string;
    }[];
    campaignLang?: string;
  };
};
export type GetUserOrdersApiResponse = /** status 200 OK */ OrderItemForOrderListInUserUi[];
export type GetUserOrdersApiArg = void;
export type UpdateOrderApiResponse = /** status 204 No Content */ {};
export type UpdateOrderApiArg = {
  /** ID of order to update */
  orderId: Uuid;
  body: {
    status?: OrderStatus;
    logisticsInformation?: {
      providers?: string;
      number?: string;
      link?: string;
    };
    address?: AddressItem;
  };
};
export type DeleteUserOrdersByOrderIdApiResponse = unknown;
export type DeleteUserOrdersByOrderIdApiArg = {
  /** order id */
  orderId: string;
};
export type GetUserApiResponse = /** status 200 OK */ UserItemForEdit;
export type GetUserApiArg = void;
export type PatchUserApiResponse = unknown;
export type PatchUserApiArg = {
  body: {
    ids?: Uuid[];
    method?: string;
  };
};
export type PatchUserGeneralApiResponse = /** status 204 No Content */ {};
export type PatchUserGeneralApiArg = {
  body: {
    general?: {
      avatarUrl?: string;
      status?: UserStatus;
      gender?: 'male' | 'female' | 'other';
      firstName?: string;
      lastName?: string;
      email?: string;
      phoneNumber?: string;
      interests?: Uuid[];
      tags?: TagList;
      clubs?: Uuid[];
      notes?: string;
      phoneActivated?: boolean;
      mailActivated?: boolean;
      birthdate?: string;
    };
  };
};
export type PatchUserPasswordApiResponse = /** status 204 No Content */ {};
export type PatchUserPasswordApiArg = {
  body: {
    oldPassword: string;
    newPassword: string;
  };
};
export type PatchUserLangApiResponse = /** status 204 No Content */ {};
export type PatchUserLangApiArg = {
  body: {
    lang?: Lang;
  };
};
export type PatchUserEmailApiResponse = /** status 204 No Content */ {};
export type PatchUserEmailApiArg = {
  body: {
    email?: string;
  };
};
export type PatchUserPhoneNumberApiResponse = /** status 204 No Content */ {};
export type PatchUserPhoneNumberApiArg = {
  body: {
    phoneNumber?: string;
  };
};
export type CreateNewAddressForOneUserApiResponse =
  /** status 201 Get addresses data */ AddressItemPost[];
export type CreateNewAddressForOneUserApiArg = {
  /** A JSON Object containing order status information */
  addressItemPost: AddressItemPost;
};
export type UpdateOneUserAddressApiResponse = /** status 200 OK */ AddressItem[];
export type UpdateOneUserAddressApiArg = {
  /** address Id */
  addressId: string;
  /** A JSON Object containing order status information */
  addressItem: AddressItem;
};
export type DeleteOneUserAddressApiResponse = /** status 204 No Content */ {};
export type DeleteOneUserAddressApiArg = {
  /** address Id */
  addressId: string;
};
export type GetLanguagesApiResponse = /** status 200 OK */ {
  id?: string;
  languageCode?: string;
  languageName?: string;
}[];
export type GetLanguagesApiArg = void;
export type PostLanguagesApiResponse = unknown;
export type PostLanguagesApiArg = {
  clubItemForPost: ClubItemForPost;
};
export type GetUserChatConversationsApiResponse = /** status 200 OK */ {
  pagination?: {
    limit?: number;
  };
  data?: ConversationItemForUserChat[];
};
export type GetUserChatConversationsApiArg = {
  /** Search by keyword */
  search?: string;
  /** Limit on the number of conversations obtained */
  limit?: string;
  /** The last conversation id in the conversation list */
  cursor?: string;
};
export type PostUserChatNewConversationApiResponse = /** status 200 OK */ {
  conversationId?: string;
};
export type PostUserChatNewConversationApiArg = {
  /** Order id for create a new conversation */
  body: {
    orderId?: Uuid;
  };
};
export type GetUserChatConversationsByConversationIdMessagesApiResponse =
  /** status 200 OK */ MessageToSendItemForChat[];
export type GetUserChatConversationsByConversationIdMessagesApiArg = {
  /** Conversation id */
  conversationId: string;
  /** Limit on the number of conversations obtained */
  limit?: number;
  /** The last message id in the message list */
  cursor?: string;
};
export type PostUserChatConversationsByConversationIdMessagesApiResponse =
  /** status 201 Created */ MessageToSendItemForChat2;
export type PostUserChatConversationsByConversationIdMessagesApiArg = {
  /** Conversation id */
  conversationId: string;
  body: {
    message?: string;
    senderId?: string;
    attachments?: {
      name?: string;
      contentType?: ContentTypeForChatMessage;
      mediaPath?: string;
      createdAt?: string;
    }[];
    socketId?: string;
  };
};
export type PostUserChatConversationsByConversationIdMarkAsReadApiResponse = unknown;
export type PostUserChatConversationsByConversationIdMarkAsReadApiArg = {
  /** conversation id  */
  conversationId: string;
};
export type GetUserInterestsApiResponse = /** status 200 OK */ {
  id?: Uuid;
  name?: string;
}[];
export type GetUserInterestsApiArg = void;
export type PostUserInterestsApiResponse = /** status 200 OK */ {
  name?: string;
};
export type PostUserInterestsApiArg = void;
export type GetUserNotificationsApiResponse = /** status 200 OK */ {
  id?: Uuid;
  title?: string;
  description?: string;
  avatar?: string;
  type?: string;
  createdAt?: string;
  readAt?: string;
}[];
export type GetUserNotificationsApiArg = {
  /** Limit on the number of notifications obtained */
  limit?: string;
  /** The last notification id in the notifications list */
  cursor?: string;
};
export type PatchUserNotificationsApiResponse = unknown;
export type PatchUserNotificationsApiArg = {
  body: {
    notifications?: NotificationListForUser;
  };
};
export type PutUserNotificationsReadApiResponse = unknown;
export type PutUserNotificationsReadApiArg = {
  body: {
    notificationIds?: string[];
  };
};
export type PostUserRegistrationApiResponse = /** status 201 Created */ {
  user?: {
    id?: string;
    status?: string;
  };
  token?: string;
  refreshToken?: string;
};
export type PostUserRegistrationApiArg = {
  body: {
    firstName?: string;
    lastName?: string;
    email?: string;
    password?: string;
    lang?: Lang;
  };
};
export type PostUserRegistrationResendEmailCodeApiResponse = unknown;
export type PostUserRegistrationResendEmailCodeApiArg = void;
export type PostUserRegistrationCheckEmailApiResponse = unknown;
export type PostUserRegistrationCheckEmailApiArg = {
  body: {
    code?: string;
  };
};
export type PostUserRegistrationGeneralApiResponse = unknown;
export type PostUserRegistrationGeneralApiArg = {
  userItemForPostRegistrationGeneral: UserItemForSignup;
};
export type PostUserRegistrationResendPhoneCodeApiResponse = unknown;
export type PostUserRegistrationResendPhoneCodeApiArg = void;
export type PostUserRegistrationCheckPhoneNumberApiResponse = unknown;
export type PostUserRegistrationCheckPhoneNumberApiArg = {
  body: {
    code?: string;
  };
};
export type GetUserUserCampaignsApiResponse =
  /** status 200 OK */ CampaignItemForListOfCampaignsInUserUi[];
export type GetUserUserCampaignsApiArg = void;
export type GetUserChatPossibleConversationsApiResponse = /** status 200 OK */ {
  /** order id */
  id?: string;
  status?: OrderStatus;
  campaign?: {
    id?: string;
    name?: string;
    featuredImage?: string;
    lang?: string;
  };
  brand?: {
    id?: string;
    name?: string;
    logoPath?: string;
  };
}[];
export type GetUserChatPossibleConversationsApiArg = {
  /** Search by keyword, find order with brand name or campaign name */
  search?: string;
};
export type GetUserMediasStatisticsApiResponse = /** status 200 OK */ {
  instagram?: InstagramStatisticForUserSocialNetworkMedia;
  youtube?: YouTubeStatisticForUserSocialNetworkMedia;
  tiktok?: TikTokStatisticForUserSocialNetworkMedia;
  blog?: BlogStatistics;
};
export type GetUserMediasStatisticsApiArg = void;
export type GetUserIgMediasApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUserIgMediasApiArg = {
  /** last media id from media list */
  cursor?: string;
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** Limit on the number of conversations obtained */
  limit?: string;
};
export type GetUsersByUserIdIgStoriesApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUsersByUserIdIgStoriesApiArg = {
  /** user id */
  userId: string;
  /** the last common mediaId on the stories list */
  cursor?: string;
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** Limit on the number of conversations obtained */
  limit?: string;
};
export type GetUserIgStoriesApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUserIgStoriesApiArg = {
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** the last common mediaId on the stories list */
  cursor?: string;
  /** Limit on the number of conversations obtained */
  limit?: string;
};
export type GetUserIgReelsApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUserIgReelsApiArg = {
  /** last media id from media list */
  cursor?: string;
  /** get data from instagram api */
  'get-fresh-data'?: boolean;
  /** Limit on the number of conversations obtained */
  limit?: string;
};
export type DeleteUserOrdersByOrderIdMediasAndMediaIdApiResponse = unknown;
export type DeleteUserOrdersByOrderIdMediasAndMediaIdApiArg = {
  /** order id */
  orderId: string;
  /** media id */
  mediaId: string;
};
export type GetUserOrdersByOrderIdIgMediasApiResponse =
  /** status 200 OK */ MediaContentItemForOrder[];
export type GetUserOrdersByOrderIdIgMediasApiArg = {
  /** order Id */
  orderId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
};
export type PostUserOrdersByOrderIdIgMediasApiResponse = unknown;
export type PostUserOrdersByOrderIdIgMediasApiArg = {
  /** order Id */
  orderId: string;
  body: {
    newMedia?: UploadContentItem;
  };
};
export type PatchUserOrdersByOrderIdIgMediasApiResponse = unknown;
export type PatchUserOrdersByOrderIdIgMediasApiArg = {
  /** order Id */
  orderId: string;
  /** array of medias ids selected */
  body: string[];
};
export type GetUserOrdersByOrderIdIgStoriesApiResponse =
  /** status 200 OK */ MediaContentItemForOrder[];
export type GetUserOrdersByOrderIdIgStoriesApiArg = {
  /** order Id */
  orderId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
};
export type PostUserOrdersByOrderIdIgStoriesApiResponse = unknown;
export type PostUserOrdersByOrderIdIgStoriesApiArg = {
  /** order Id */
  orderId: string;
  body: {
    newMedia?: UploadContentItem;
  };
};
export type PatchUserOrdersByOrderIdIgStoriesApiResponse = unknown;
export type PatchUserOrdersByOrderIdIgStoriesApiArg = {
  /** order Id */
  orderId: string;
  /** array of stories ids selected */
  body: string[];
};
export type GetUserOrdersByOrderIdIgReelsApiResponse =
  /** status 200 OK */ MediaContentItemForOrder[];
export type GetUserOrdersByOrderIdIgReelsApiArg = {
  /** order id */
  orderId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
};
export type PostUserOrdersByOrderIdIgReelsApiResponse = unknown;
export type PostUserOrdersByOrderIdIgReelsApiArg = {
  /** order id */
  orderId: string;
  body: {
    newMedia?: UploadContentItem;
  };
};
export type PatchUserOrdersByOrderIdIgReelsApiResponse = unknown;
export type PatchUserOrdersByOrderIdIgReelsApiArg = {
  /** order id */
  orderId: string;
  /** array of reels ids selected */
  body: string[];
};
export type GetUserYoutubeApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUserYoutubeApiArg = {
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get data from youtube api */
  'get-fresh-data'?: boolean;
};
export type GetUserOrdersByOrderIdYoutubeApiResponse =
  /** status 200 OK */ MediaContentItemForOrder[];
export type GetUserOrdersByOrderIdYoutubeApiArg = {
  /** order id */
  orderId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
};
export type PostUserOrdersByOrderIdYoutubeApiResponse = unknown;
export type PostUserOrdersByOrderIdYoutubeApiArg = {
  /** order id */
  orderId: string;
  uploadYoutubeContentItem: UploadManualYoutubeContentItem;
};
export type PatchUserOrdersByOrderIdYoutubeApiResponse = unknown;
export type PatchUserOrdersByOrderIdYoutubeApiArg = {
  /** order id */
  orderId: string;
  body: string[];
};
export type GetUserYoutubeShortsApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUserYoutubeShortsApiArg = {
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get fresh data from youtube api */
  'get-fresh-data'?: boolean;
};
export type GetUserOrdersByOrderIdYoutubeShortsApiResponse =
  /** status 200 OK */ MediaContentItemForOrder[];
export type GetUserOrdersByOrderIdYoutubeShortsApiArg = {
  /** order id */
  orderId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
};
export type PostUserOrdersByOrderIdYoutubeShortsApiResponse = unknown;
export type PostUserOrdersByOrderIdYoutubeShortsApiArg = {
  /** order id */
  orderId: string;
  uploadYoutubeContentItem: UploadManualYoutubeContentItem;
};
export type PatchUserOrdersByOrderIdYoutubeShortsApiResponse = unknown;
export type PatchUserOrdersByOrderIdYoutubeShortsApiArg = {
  /** order id */
  orderId: string;
  body: string[];
};
export type GetUserTiktokApiResponse = /** status 200 OK */ MediaContentItemForFeed[];
export type GetUserTiktokApiArg = {
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
  /** get the latest data from Tiktok api */
  'get-fresh-data'?: boolean;
};
export type GetUserOrdersByOrderIdTiktokApiResponse =
  /** status 200 OK */ MediaContentItemForOrder[];
export type GetUserOrdersByOrderIdTiktokApiArg = {
  /** order id */
  orderId: string;
  /** default is 1, user click "load more" button, pagination will increment and get more contents */
  pagination?: string;
};
export type PostUserOrdersByOrderIdTiktokApiResponse = unknown;
export type PostUserOrdersByOrderIdTiktokApiArg = {
  /** order id */
  orderId: string;
  body: {
    link?: string;
    views?: number;
    likes?: number;
    comments?: number;
    shares?: number;
    sparkAdCode?: string;
  };
};
export type PatchUserOrdersByOrderIdTiktokApiResponse = unknown;
export type PatchUserOrdersByOrderIdTiktokApiArg = {
  /** order id */
  orderId: string;
  body: {
    content?: {
      nativeMediaId?: string;
      sparkAdCode?: string;
    }[];
  };
};
export type PatchUserOrdersByOrderIdTiktokAndContentIdApiResponse = unknown;
export type PatchUserOrdersByOrderIdTiktokAndContentIdApiArg = {
  /** order id */
  orderId: string;
  /** content id */
  contentId: string;
  body: {
    sparkAdCode?: string;
  };
};
export type GetUserOrdersByOrderIdBlogApiResponse = /** status 200 OK */ {
  blogLink?: string;
};
export type GetUserOrdersByOrderIdBlogApiArg = {
  /** order id */
  orderId: string;
};
export type PostUserOrdersByOrderIdBlogApiResponse = unknown;
export type PostUserOrdersByOrderIdBlogApiArg = {
  /** order id */
  orderId: string;
  body: {
    blogLink?: string;
  };
};
export type PostUserUploadApiResponse = /** status 201 Upload successful */ string[];
export type PostUserUploadApiArg = {
  body: {
    destination?: string;
    file?: Blob[];
  };
};
export type PostUserRefreshTokenApiResponse = /** status 200 OK */ {
  token?: string;
  refreshToken?: string;
};
export type PostUserRefreshTokenApiArg = {
  body: {
    id?: Uuid;
    refreshToken?: string;
  };
};
export type PostUserResetPasswordApiResponse = unknown;
export type PostUserResetPasswordApiArg = {
  body: {
    newPassword?: string;
    token?: string;
  };
};
export type GetUserChatConversationsByConversationIdFilesApiResponse = /** status 200 OK */ {
  name?: string;
  type?: 'image' | 'video' | 'pdf' | 'word' | 'excel' | 'txt';
  createdAt?: string;
  mediaPath?: string;
}[];
export type GetUserChatConversationsByConversationIdFilesApiArg = {
  /** conversation id */
  conversationId: string;
  /** user id */
  userId: string;
};
export type PostUserChatUploadApiResponse = /** status 201 Upload successful */ {
  name?: string;
  contentType?: ContentTypeForChatMessage;
  createdAt?: string;
  mediaPath?: string;
}[];
export type PostUserChatUploadApiArg = {
  body: {
    file?: Blob[];
  };
};
export type GetUserBrandsApiResponse = /** status 200 OK */ BrandItemInBrandList[];
export type GetUserBrandsApiArg = void;
export type GetUserIndustriesApiResponse = /** status 200 OK */ any[];
export type GetUserIndustriesApiArg = void;
export type PostUserChatAuthApiResponse = /** status 200 OK */ AuthItem;
export type PostUserChatAuthApiArg = {
  body: {
    userId?: Uuid;
  };
};
export type PostUserNotificationsAuthApiResponse =
  /** status 200 OK */ AuthItem | /** status 201 Created */ void;
export type PostUserNotificationsAuthApiArg = {
  body: {
    userId?: Uuid;
  };
};
export type GetUserNotificationsPusherBeamsAuthApiResponse = /** status 200 OK */ {
  token?: string;
};
export type GetUserNotificationsPusherBeamsAuthApiArg = void;
export type GetUserIgSetCardApiResponse = /** status 200 OK */ {
  name?: string;
  interests?: string[];
  avatarUrl?: string;
  socialMediaNetwork?: {
    type?: string;
    stats?: {
      postsEngagementRate?: string;
      reelsEngagementRate?: string;
      storyViewRate?: string;
      followers?: number;
      engagementRate?: string;
    };
    audienceDemographic?: {
      male?: string;
      female?: string;
      audiencePercentage?: {
        '13-17'?: number;
        '25-34'?: number;
        '35-44'?: number;
      };
    };
    biography?: string;
    audienceCountry?: {
      countryName?: string;
      countryFlag?: string;
      percentage?: string;
    };
    link?: string;
    contents?: {
      imageUrl?: string;
      likes?: number;
    }[];
  };
};
export type GetUserIgSetCardApiArg = void;
export type PostUserConsentApiResponse = unknown;
export type PostUserConsentApiArg = {
  body: {
    consent?: boolean;
  };
};
export type PatchUserOnboardingApiResponse = unknown;
export type PatchUserOnboardingApiArg = {
  body: {
    onboardingStep?: string;
  };
};
export type PatchUserOrdersByOrderIdStatusApiResponse = unknown;
export type PatchUserOrdersByOrderIdStatusApiArg = {
  orderId: string;
  body: {
    status?: OrderStatus;
  };
};
export type GetUserLeaderboardApiResponse = /** status 200 OK */ Leaderboard;
export type GetUserLeaderboardApiArg = void;
export type Lang = 'en' | 'fr' | 'cn';
export type Uuid = string;
export type ArrayOfImagePaths = string[];
export type Date = string;
export type ArrayOfStrings = string[];
export type CampaignItemForSingleCampaignInUserUi = {
  id?: Uuid;
  descriptionImages?: ArrayOfImagePaths;
  inspirationImages?: ArrayOfImagePaths;
  bannerImage?: string;
  featuredImage?: string;
  socialNetworks?: {
    name?: string;
    details?: string;
  }[];
  stock?: number;
  price?: number;
  teaser?: boolean;
  startDate?: Date;
  endDate?: Date;
  hashtags?: ArrayOfStrings;
  mentions?: ArrayOfStrings;
  campaignLink?: string;
  youtubeLink?: string;
  options?: {
    name?: string;
    content?: string[];
  }[];
  status?: string;
  total?: number;
  campaignDescription?: string;
  lang?: Lang;
  campaignDetails?: string;
  name?: string;
  requirements?: string;
  ctaText?: string;
  brand?: {
    id?: Uuid;
    logoPath?: string;
    name?: string;
  };
  paid?: boolean;
};
export type AddressItemForOrders = {
  city?: string;
  title?: string;
  address?: string;
  country?: string;
  default?: boolean;
  zipCode?: string;
  lastName?: string;
  createdAt?: Date;
  firstName?: string;
  updatedAt?: Date;
  supplement?: string;
};
export type OrderStatus =
  | 'pending'
  | 'rejected'
  | 'readyToShip'
  | 'shipped'
  | 'received'
  | 'published'
  | 'validated'
  | 'timedOut';
export type AddressItemPost = {
  id?: Uuid;
  city: string;
  title: string;
  userid?: Uuid;
  address: string;
  country: string;
  default: boolean;
  zipCode: string;
  lastName?: string;
  createdAt?: Date;
  firstName?: string;
  updatedAt?: Date;
  supplement?: string;
};
export type MediaTypeForOrder =
  | 'instagramPost'
  | 'instagramCarousel'
  | 'instagramVideo'
  | 'instagramStory'
  | 'instagramReel'
  | 'youtubeVideo'
  | 'youtubeShorts'
  | 'tiktokVideo'
  | 'blog';
export type MetricsSchema = {
  [key: string]: number;
};
export type MediaAttributesSchema = object;
export type MediaContentItemForOrder = {
  id?: Uuid;
  mediaType?: MediaTypeForOrder;
  metrics?: MetricsSchema;
  publishDate?: string;
  status?: 'published' | 'validated';
  nativeMediaId?: string;
  mediaPath?: string[];
  attributes?: MediaAttributesSchema;
  brandName?: string;
  campaignName?: {
    text?: string;
    lang?: Lang;
  }[];
};
export type OrderItemForOrderListInUserUi = {
  id: Uuid;
  status: OrderStatus;
  address: AddressItemPost;
  logisticsInformation?: {
    number: string;
    providers: string;
    createdAt: Date;
    updatedAt?: Date;
    link?: string;
  };
  createdAt: Date;
  updatedAt: Date;
  contents?: MediaContentItemForOrder[];
  unreadMessagesCount?: number;
  campaign: {
    id?: Uuid;
    featuredImage?: string;
    mentions?: string[];
    campaignLink?: string;
    name?: string;
    lang?: Lang;
    brand?: {
      name?: string;
      logoPath?: string;
    };
    socialNetworks?: {
      name?: string;
      detail?: string;
    }[];
    hashtags?: string[];
  };
  /** Field is only in response if there is conversation between the user and the brand on this campaign. If not the field is not there */
  conversationId?: string;
  options?: {
    key?: string;
    value?: string;
  }[];
};
export type AddressItem = {
  id?: Uuid;
  title?: string;
  default?: boolean;
  userId?: Uuid;
  address?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  supplement?: string;
  firstName?: string;
  lastName?: string;
  createdAt?: Date;
  updatedAt?: Date;
};
export type UserStatus = 'registered' | 'incomplete' | 'refused' | 'active' | 'suspended';
export type SocialNetworkItem = {
  /** blog is for user item, website is for brand item */
  name?:
    | 'instagram'
    | 'tiktok'
    | 'youtube'
    | 'blog'
    | 'website'
    | 'facebook'
    | 'linkedin'
    | 'twitter';
  link?: string;
  accountName?: string;
  tokenStatus?: boolean;
  tokenExpiry?: number;
  reauthorize?: boolean;
};
export type NotificationListForUser = {
  userId?: Uuid;
  EmailMeWhenIGetAMessageFromTribz?: boolean;
  EmailMeWhenTheStatusOfMyOrderChanges?: boolean;
  NewsAndAnnouncements?: boolean;
  WeeklyProductUpdates?: boolean;
  WeeklyBlogDigest?: boolean;
};
export type AudienceCountrySchema = {
  [key: string]: number;
};
export type AudienceCitySchema = {
  [key: string]: number;
};
export type InstagramStatisticForUserSocialNetworkMedia = {
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  engagementRate?: number;
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  postsEngagementRate?: number;
  /** engagement rate is all of interactions (likes, dislikes and comments) divided by followers of this account */
  reelsEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  tribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  postsTribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) divided by reach of this account */
  reelsTribzTrueEngagementRate?: number;
  followers?: number;
  following?: number;
  reach30Day?: number;
  impressions30Day?: number;
  posts?: number;
  averagePostImpressions?: number;
  postImpressionRate?: number;
  averagePostReach?: number;
  postReachRate?: number;
  averagePostComments?: number;
  postsPerMonth?: number;
  averagePostLikes?: number;
  averageCarouselLikes?: number;
  averageStoryViews?: number;
  storyViewRate?: number;
  /** replies for story */
  averageStoryComments?: number;
  averageStoryClicks?: number;
  averageStoryLikes?: number;
  averageReelViews?: number;
  reelViewRate?: number;
  averageReelReach?: number;
  averageReelComments?: number;
  reelReachRate?: number;
  averageReelLikes?: number;
  audienceCountry?: AudienceCountrySchema;
  audienceCity?: AudienceCitySchema;
  lastPost?: string;
  audienceDemographic?: {
    'F.13-17'?: number;
    'F.18-24'?: number;
    'F.25-34'?: number;
    'F.35-44'?: number;
    'F.45-54'?: number;
    'F.55-64'?: number;
    'F.65+'?: number;
    'M.13-17'?: number;
    'M.18-24'?: number;
    'M.25-34'?: number;
    'M.35-44'?: number;
    'M.45-54'?: number;
    'M.55-64'?: number;
    'M.65+'?: number;
    'U.13-17'?: number;
    'U.18-24'?: number;
    'U.25-34'?: number;
    'U.35-44'?: number;
    'U.45-54'?: number;
    'U.55-64'?: number;
    'U.65+'?: number;
  };
};
export type YouTubeStatisticForUserSocialNetworkMedia = {
  /** engagement rate is all of interactions (likes, dislikes and comments) of Youtube normal Video and Shorts divided by followers of this account */
  engagementRate?: number;
  /** YouTube Video engagement rate is all of interactions (likes, dislikes and comments) of YouTube normal Video divided by followers of this account */
  videoEngagementRate?: number;
  /** YouTube Shorts engagement rate is all of interactions (likes, dislikes and comments) of YouTube Shorts divided by followers of this account */
  shortsEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) of Youtube normal Video and Shorts divided by views or impressions  of this account */
  tribzTrueEngagementRate?: number;
  /** TTER is all of interactions (likes, dislikes and comments) of Youtube normal Video divided by views or impressions  of this account */
  videoTribzTrueEngagementRate?: number;
  /** YouTube Shorts TTER is all of interactions (likes, dislikes and comments) of YouTube Shorts divided by views or impressions  of this account */
  shortsTribzTrueEngagementRate?: number;
  averageDislikes?: number;
  shortsAverageDislikes?: number;
  averageLikes?: number;
  shortsAverageLikes?: number;
  averageViews?: number;
  shortsAverageViews?: number;
  viewRate?: number;
  shortsViewRate?: number;
  followers?: number;
  videos?: number;
  shortsVideos?: number;
  averageComments?: number;
  shortsAverageComments?: number;
  audienceCountry?: AudienceCountrySchema;
  audienceDemographic?: {
    'F.13-17'?: number;
    'F.18-24'?: number;
    'F.25-34'?: number;
    'F.35-44'?: number;
    'F.45-54'?: number;
    'F.55-64'?: number;
    'F.65+'?: number;
    'M.13-17'?: number;
    'M.18-24'?: number;
    'M.25-34'?: number;
    'M.35-44'?: number;
    'M.45-54'?: number;
    'M.55-64'?: number;
    'M.65+'?: number;
    'U.13-17'?: number;
    'U.18-24'?: number;
    'U.25-34'?: number;
    'U.35-44'?: number;
    'U.45-54'?: number;
    'U.55-64'?: number;
    'U.65+'?: number;
  };
};
export type TikTokStatisticForUserSocialNetworkMedia = {
  engagementRate?: number;
  tribzTrueEngagementRate?: number;
  totalLikes?: number;
  followers?: number;
  following?: number;
  totalViews?: number;
};
export type BlogStatistics = {
  link?: string;
  monthlyVisitors?: number;
  /** EMV for blog */
  emv?: number;
};
export type UserSocialNetworkStatisticsByMedia = {
  instagram?: InstagramStatisticForUserSocialNetworkMedia;
  youtube?: YouTubeStatisticForUserSocialNetworkMedia;
  tiktok?: TikTokStatisticForUserSocialNetworkMedia;
  blog?: BlogStatistics;
};
export type News = {
  id?: Uuid;
  title?: string;
  category?: 'alert' | 'update';
  status?: 'published' | 'unpublished';
  createdAt?: Date;
  description?: string;
};
export type UserItemForEdit = {
  id?: Uuid;
  avatarUrl?: string;
  status?: UserStatus;
  mailActivated?: boolean;
  phoneActivated?: boolean;
  credits?: number;
  usedCredits?: number;
  gender?: 'male' | 'female' | 'other';
  firstName?: string;
  lastName?: string;
  email?: string;
  lang?: Lang;
  phoneNumber?: string;
  birthdate?: string;
  /** Insterests is a array of interests id seleted  */
  interests?: Uuid[];
  addresses?: AddressItem[];
  socialNetworkLinks?: SocialNetworkItem[];
  notifications?: NotificationListForUser;
  socialNetworksStatisticsByMedia?: UserSocialNetworkStatisticsByMedia;
  role?: 'admin' | 'influencer';
  unreadNotifications?: number;
  onboardingStep?: string;
  consent?: boolean;
  news?: News[];
  rankPoints?: number;
};
export type TagList = string[];
export type ClubItemForPost = {
  id?: Uuid;
  name?: string;
  description?: string;
  url?: string;
  status?: 'active' | 'pending';
  logoPath?: string;
  bannerPath?: string;
  clubTag?: string;
  landingPage?: boolean;
};
export type ChatStatus = 'online' | 'offline';
export type ConversationItemForUserChat = {
  id?: string;
  brandName?: string;
  campaignId?: string;
  campaignName?: string;
  brandLogo?: string;
  lastMsg?: string;
  lastMsgTime?: string;
  unreadMessagesCount?: number;
  lang?: Lang;
  status?: ChatStatus;
};
export type ContentTypeForChatMessage =
  | 'text'
  | 'video'
  | 'image'
  | 'pdf'
  | 'word'
  | 'excel'
  | 'txt';
export type MessageToSendItemForChat = {
  /** message id */
  id?: string;
  message?: string;
  contentTypes?: ContentTypeForChatMessage[];
  attachments?: string[];
  senderId?: string;
  createdAt?: string;
  isUnread?: boolean;
};
export type MessageToSendItemForChat2 = {
  conversationId?: Uuid;
  message?: string;
  contentTypes?: ContentTypeForChatMessage[];
  attachments?: Blob[];
  createdAt?: Date;
  senderId?: Uuid;
  socketId?: string;
};
export type AddressItemPostForUserSignup = {
  address?: string;
  country?: string;
  city?: string;
  supplement?: string;
  zipCode?: string;
  title?: string;
};
export type UserItemForSignup = {
  id?: Uuid;
  gender?: 'male' | 'female' | 'other';
  firstName?: string;
  lastName?: string;
  email?: string;
  birthdate?: string;
  phoneNumber?: string;
  address?: AddressItemPostForUserSignup;
  interests?: string[];
  agreePrivacyPolicy?: boolean;
};
export type CampaignItemForListOfCampaignsInUserUi = {
  id?: Uuid;
  featuredImage?: string;
  teaser?: boolean;
  price?: number;
  startDate?: string;
  endDate?: string;
  name?: string;
  lang?: Lang;
  ctaText?: string;
  descriptionImages?: ArrayOfImagePaths;
  socialNetworks?: string[];
  brand?: {
    id?: Uuid;
    name?: string;
    logoPath?: string;
  };
  interests?: Uuid[];
  paid?: boolean;
};
export type MediaContentItemForFeed = {
  nativeMediaId?: string;
  commonMediaId?: Uuid;
  mediaType?: MediaTypeForOrder;
  /** media_url (for instagram post and carousel) or thumbnail_url (for instagram video) */
  mediaPath?: string[];
  metrics?: MetricsSchema;
  attributes?: MediaAttributesSchema;
  publishDate?: string;
  /** permalink */
  link?: string;
};
export type UploadContentItem = {
  file?: Blob;
  views?: number;
  reach?: number;
  likes?: number;
  comments?: number;
};
export type UploadManualYoutubeContentItem = {
  link: string;
  likes: number;
  views: number;
  comments: number;
  favorites?: number;
};
export type BrandStatus = 'active' | 'on hold' | 'deactivated';
export type BrandItemInBrandList = {
  id?: string;
  name?: string;
  logoPath?: string;
  group?: string;
  activeCampaigns?: number;
  totalCampaigns?: number;
  totalContents?: number;
  status?: BrandStatus;
};
export type AuthItem = {
  auth?: string;
  channel_data?: string;
};
export type Leaderboard = {
  id?: Uuid;
  fullName?: string;
  points?: number;
  rank?: number;
  avatarUrl?: string;
}[];
export const {
  useLoginMutation,
  useFacebookLoginMutation,
  useGoogleLoginMutation,
  useLogoutMutation,
  usePostUserBlogMutation,
  usePostUserForgotPasswordMutation,
  useGetUserUserCampaignsByCampaignIdQuery,
  usePostUserOrdersMutation,
  useGetUserOrdersQuery,
  useUpdateOrderMutation,
  useDeleteUserOrdersByOrderIdMutation,
  useGetUserQuery,
  usePatchUserMutation,
  usePatchUserGeneralMutation,
  usePatchUserPasswordMutation,
  usePatchUserLangMutation,
  usePatchUserEmailMutation,
  usePatchUserPhoneNumberMutation,
  useCreateNewAddressForOneUserMutation,
  useUpdateOneUserAddressMutation,
  useDeleteOneUserAddressMutation,
  useGetLanguagesQuery,
  usePostLanguagesMutation,
  useGetUserChatConversationsQuery,
  usePostUserChatNewConversationMutation,
  useGetUserChatConversationsByConversationIdMessagesQuery,
  usePostUserChatConversationsByConversationIdMessagesMutation,
  usePostUserChatConversationsByConversationIdMarkAsReadMutation,
  useGetUserInterestsQuery,
  usePostUserInterestsMutation,
  useGetUserNotificationsQuery,
  usePatchUserNotificationsMutation,
  usePutUserNotificationsReadMutation,
  usePostUserRegistrationMutation,
  usePostUserRegistrationResendEmailCodeMutation,
  usePostUserRegistrationCheckEmailMutation,
  usePostUserRegistrationGeneralMutation,
  usePostUserRegistrationResendPhoneCodeMutation,
  usePostUserRegistrationCheckPhoneNumberMutation,
  useGetUserUserCampaignsQuery,
  useGetUserChatPossibleConversationsQuery,
  useGetUserMediasStatisticsQuery,
  useGetUserIgMediasQuery,
  useGetUsersByUserIdIgStoriesQuery,
  useGetUserIgStoriesQuery,
  useGetUserIgReelsQuery,
  useDeleteUserOrdersByOrderIdMediasAndMediaIdMutation,
  useGetUserOrdersByOrderIdIgMediasQuery,
  usePostUserOrdersByOrderIdIgMediasMutation,
  usePatchUserOrdersByOrderIdIgMediasMutation,
  useGetUserOrdersByOrderIdIgStoriesQuery,
  usePostUserOrdersByOrderIdIgStoriesMutation,
  usePatchUserOrdersByOrderIdIgStoriesMutation,
  useGetUserOrdersByOrderIdIgReelsQuery,
  usePostUserOrdersByOrderIdIgReelsMutation,
  usePatchUserOrdersByOrderIdIgReelsMutation,
  useGetUserYoutubeQuery,
  useGetUserOrdersByOrderIdYoutubeQuery,
  usePostUserOrdersByOrderIdYoutubeMutation,
  usePatchUserOrdersByOrderIdYoutubeMutation,
  useGetUserYoutubeShortsQuery,
  useGetUserOrdersByOrderIdYoutubeShortsQuery,
  usePostUserOrdersByOrderIdYoutubeShortsMutation,
  usePatchUserOrdersByOrderIdYoutubeShortsMutation,
  useGetUserTiktokQuery,
  useGetUserOrdersByOrderIdTiktokQuery,
  usePostUserOrdersByOrderIdTiktokMutation,
  usePatchUserOrdersByOrderIdTiktokMutation,
  usePatchUserOrdersByOrderIdTiktokAndContentIdMutation,
  useGetUserOrdersByOrderIdBlogQuery,
  usePostUserOrdersByOrderIdBlogMutation,
  usePostUserUploadMutation,
  usePostUserRefreshTokenMutation,
  usePostUserResetPasswordMutation,
  useGetUserChatConversationsByConversationIdFilesQuery,
  usePostUserChatUploadMutation,
  useGetUserBrandsQuery,
  useGetUserIndustriesQuery,
  usePostUserChatAuthMutation,
  usePostUserNotificationsAuthMutation,
  useGetUserNotificationsPusherBeamsAuthQuery,
  useGetUserIgSetCardQuery,
  usePostUserConsentMutation,
  usePatchUserOnboardingMutation,
  usePatchUserOrdersByOrderIdStatusMutation,
  useGetUserLeaderboardQuery,
} = injectedRtkApi;
